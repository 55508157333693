<template>
  <div>
    <!-- <v-card outlined style="border-radius:20px;" class="py-1 px-5" min-height="100%">  -->
      <!-- <div class="success--text overline "><v-icon size="16" color="success">mdi-database-check-outline</v-icon> SALDO DISPONIVEL</div> -->
      <!-- <div class="headline mt-0 font-weight-black">R$ 20.000,00</div> -->

      <default-count-card :value="data" :title="title" :icon="icon" :title_class="title_class" :icon_color="icon_color" style="height: 100%" >
        <template v-slot:extra>
          
          <!-- <v-tooltip bottom color='black'>
            <template v-slot:activator="{on}">
              <div v-on="on" class="subtitle-2" style="text-align: right;">E - S - ER + RR</div>
            </template>
            <div class="subtitle-2" style="text-align: right;">Entradas - Saídas - Entradas Reserva + Retirada Reserva</div>
          </v-tooltip> -->

        </template>
      </default-count-card>

    <!-- </v-card> -->
  </div>
</template>

<script>
  export default {
    name: 'CountInscriptions',

    components: {
      DefaultCountCard: () => import ('../default_counter_card/DefaultCountCard.vue'),
    },

    props: {
      data: {
        type: Number,
      },
    },

    data() {
      return {
        // value: 15000,
        title: 'Inscritos',
        icon: 'mdi-account-plus-outline',
        title_class: 'primary--text overline text-center',
        icon_color: 'primary',
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>